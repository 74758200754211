import { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx-js-style";
import { mapToXlsx } from "./regulation-to-xlsx.mapper";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";

const DownloadSearchResults = forwardRef(
  ({
    title, data, regFilter, partsFilter, onClick,
  }, ref) => {
    const handleFileDownload = () => {
      const regulationsArray = Array.isArray(data) ? data : [data];

      const flattenedData = mapToXlsx(regulationsArray);

      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      const headers = Object.keys(flattenedData[0]);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
      worksheet["!autofilter"] = { ref: `A1:${XLSX.utils.encode_cell({ r: data.length, c: headers.length - 1 })}` };

      headers.forEach((_, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
        worksheet[cellAddress].s = {
          font: { bold: true },
          fill: { fgColor: { rgb: "D3D3D3" } },
          border: { bottom: { style: "medium", color: { rgb: "000" } } },
        };
      });

      const columnsWidth = [
        { wch: REGULATIONS_CONSTANTS.REGULATION_TITLE.length * 3 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_DESCRIPTION.length * 2 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_RULE_NO.length + 2 },
        { wch: REGULATIONS_CONSTANTS.RESTRICTION.length + 2 },
        { wch: REGULATIONS_CONSTANTS.JURISDICTION_LEVEL.length + 2 },
        { wch: REGULATIONS_CONSTANTS.COUNTRY.length + 4 },
        { wch: REGULATIONS_CONSTANTS.STATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.COUNTY.length + 4 },
        { wch: REGULATIONS_CONSTANTS.CITY.length * 4 },
        { wch: REGULATIONS_CONSTANTS.TOTAL_PARTS.length + 2 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_EFF_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_CREATED_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_CREATED_BY.length + 2 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_MODIFIED_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.REGULATION_MODIFIED_BY.length + 2 },
        { wch: REGULATIONS_CONSTANTS.TAMS_LINE_ABBRV.length + 4 },
        { wch: REGULATIONS_CONSTANTS.PART_NUMBER.length + 4 },
        { wch: REGULATIONS_CONSTANTS.PART_DESCRIPTION.length * 3 },
        { wch: REGULATIONS_CONSTANTS.CLASS_CODE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.PART_OBSOLETE_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.ALT_TAMS_LINE_ABBRV.length + 2 },
        { wch: REGULATIONS_CONSTANTS.ALT_PART_NUMBER.length + 2 },
        { wch: REGULATIONS_CONSTANTS.ALT_PART_DESCRIPTION.length * 2 },
        { wch: REGULATIONS_CONSTANTS.PART_EXPIRED_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.PART_CREATED_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.PART_CREATED_BY.length + 2 },
        { wch: REGULATIONS_CONSTANTS.PART_MODIFIED_DATE.length + 2 },
        { wch: REGULATIONS_CONSTANTS.PART_MODIFIED_BY.length + 2 },
      ];

      worksheet["!cols"] = columnsWidth;

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Regulation List");

      const fileName = `Regulation_${data.length > 1 ? "List_" : ""}${regFilter === undefined || regFilter === "" ? "All_Regulations" : regFilter}_${partsFilter === undefined || partsFilter === "" ? "All_Parts" : `Parts_${partsFilter}`}`;
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    useImperativeHandle(ref, () => ({
      handleFileDownload,
    }));

    const handleClick = (event) => {
      if (onClick) {
        onClick(event);
      } else {
        handleFileDownload(data, regFilter, partsFilter);
      }
    };
    return (
      <Tooltip title={title} placement="top">
        <IconButton
          ref={ref}
          className="download-template-button"
          sx={{ width: "48px", height: "48px" }}
          onClick={handleClick}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
    );
  },
);

DownloadSearchResults.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  regFilter: PropTypes.string.isRequired,
  partsFilter: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default DownloadSearchResults;
