import dayjs from "dayjs";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";

const formatDateIfNecessary = (input) => {
  const format = "MM/DD/YYYY";
  if (dayjs(input, format, true).isValid() || input === null) {
    return input;
  }

  const date = new Date(input);
  if (Number.isNaN(date.getTime())) {
    return "";
  }

  return dayjs(date).format(format).toString();
};

// eslint-disable-next-line import/prefer-default-export,max-len
export const mapToXlsx = (regulationsArray) => regulationsArray.flatMap((reg) => reg.parts.map((part) => ({
  [REGULATIONS_CONSTANTS.REGULATION_TITLE]: reg.regTitle,
  [REGULATIONS_CONSTANTS.REGULATION_DESCRIPTION]: reg.regDesc,
  [REGULATIONS_CONSTANTS.REGULATION_RULE_NO]: reg.ruleNo,
  [REGULATIONS_CONSTANTS.RESTRICTION]: reg.regType,
  [REGULATIONS_CONSTANTS.JURISDICTION_LEVEL]: reg.jurisdictionLevel,
  [REGULATIONS_CONSTANTS.COUNTRY]: reg.countryCode,
  [REGULATIONS_CONSTANTS.STATE]: reg.stateCode,
  [REGULATIONS_CONSTANTS.COUNTY]: reg.county,
  [REGULATIONS_CONSTANTS.CITY]: reg.city,
  [REGULATIONS_CONSTANTS.TOTAL_PARTS]: reg.parts.length,
  [REGULATIONS_CONSTANTS.REGULATION_EFF_DATE]: formatDateIfNecessary(reg.effDate),
  [REGULATIONS_CONSTANTS.REGULATION_CREATED_DATE]: formatDateIfNecessary(reg.createdDate),
  [REGULATIONS_CONSTANTS.REGULATION_CREATED_BY]: reg.createdBy,
  [REGULATIONS_CONSTANTS.REGULATION_MODIFIED_DATE]: formatDateIfNecessary(reg.modifiedDate),
  [REGULATIONS_CONSTANTS.REGULATION_MODIFIED_BY]: reg.modifiedBy,
  [REGULATIONS_CONSTANTS.TAMS_LINE_ABBRV]: part.lineAbbrev,
  [REGULATIONS_CONSTANTS.PART_NUMBER]: part.partNo,
  [REGULATIONS_CONSTANTS.PART_DESCRIPTION]: part.partDesc,
  [REGULATIONS_CONSTANTS.CLASS_CODE]: part.bestClass,
  [REGULATIONS_CONSTANTS.PART_OBSOLETE_DATE]: formatDateIfNecessary(part.obsoleteDate),
  [REGULATIONS_CONSTANTS.ALT_TAMS_LINE_ABBRV]: part.altLineAbbrev,
  [REGULATIONS_CONSTANTS.ALT_PART_NUMBER]: part.altPartNumber,
  [REGULATIONS_CONSTANTS.ALT_PART_DESCRIPTION]: part.altPartDesc,
  [REGULATIONS_CONSTANTS.PART_EXPIRED_DATE]: formatDateIfNecessary(part.expiredDate),
  [REGULATIONS_CONSTANTS.PART_CREATED_DATE]: formatDateIfNecessary(part.createdDate),
  [REGULATIONS_CONSTANTS.PART_CREATED_BY]: part.createdBy,
  [REGULATIONS_CONSTANTS.PART_MODIFIED_DATE]: formatDateIfNecessary(part.modifiedDate),
  [REGULATIONS_CONSTANTS.PART_MODIFIED_BY]: part.modifiedBy,
})));
